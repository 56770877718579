import React from "react";
import IHeaderProps from "./interfaces/IHeaderProps";
import {ReactComponent as Logo} from "../assets/codeandabrew-logo.svg";
//import {Col, Row} from 'react-bootstrap';

export const Header : React.FC<IHeaderProps> = ({user}: IHeaderProps) => {
    return (
        <div className="header flex">
            <div>
                <Logo  id="header-logo"/>
            </div>
            <div className="d-flex mb-5">
                Hello, {user.name}!
            </div>
        </div>
    );
}