import axios from 'axios';
import JournalEntry from '../models/JournalEntry';
import ILoginResponse from '../components/interfaces/ILoginResponse';
import QueryParams from '../models/QueryParams';

const getBaseUrl = (): string | undefined => {
    if(process.env.NODE_ENV === 'production'){
        return 'https://api.codeandabrew.com/api/';
    }
    else{
        console.log("Here");
        return `${process.env.REACT_APP_API_BASEURL}api/`;
    };
}


axios.defaults.withCredentials = true;

const instance = axios.create({
    baseURL: getBaseUrl(),
    withCredentials: true,
    timeout: 100000,
    headers: {
        'Access-Control-Allow-Origin' : '*'
    }
})

export const login = async (username : string, pass : string) : Promise<ILoginResponse | undefined> => {
    let res : undefined;

    await instance.post('user/login/',{
        email: username,
        password: pass
    },{withCredentials: true}).then((response) => {
        res = response.data;
    }).catch((reason) => {
        console.log(reason);
    });
    
    return res;
};

export const getEntries = async (params? : QueryParams) : Promise<JournalEntry[]> => {
    let res = new Array<JournalEntry>();
    
    await instance.get('journal',{
        withCredentials: true,
        params: params
    })
        .then((response) => res = response.data)
        .catch((reason) => res = reason);

    return res;
}

export const postNewEntry = async (entry: JournalEntry) : Promise<JournalEntry | null> => {
    let res = null;

    await instance.post('journal', entry, {withCredentials: true})
        .then((response) => res = response.data)
        .catch((reason) => res = undefined);

    return res;
}

export const updateEntry = async (entry: JournalEntry) : Promise<JournalEntry | null> => {
    let res = null;

    await instance.put(`journal/${entry.id}`, entry, {withCredentials: true})
    .then((response) => res = response.data)
    .catch((reason) => res = undefined);

    return res;
}

export const deleteEntry = async (id: number) : Promise<boolean> => {
    let res = false;

    await instance.delete(`journal/${id}`, {withCredentials: true})
    .then((response) => res = response.data)
    .catch((reason) => res = false);

    return res;
}
