import {Journal} from './components/Journal';
import {Header} from './components/Header';
import {Login} from './components/Login';
import './css/App.css';
import {useSelector, shallowEqual} from 'react-redux';
import { RootState } from './store';


function App() : JSX.Element {
  const user = useSelector((state : RootState) => state.user, shallowEqual)

  if (!user.isAuthorized){
    return (
      <div className='app-container'>
        <Header user={user}/>
        <Login />
      </div>
    )
  }
  else {
    return (
      <div className='app-container'>
        <Header user={user} />
        <Journal />
      </div>
    )
  };
}

export default App;
