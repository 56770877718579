import IEntryListProps from "./interfaces/IEntryListProps"
import { ChangeEvent } from "react";
import { EntryCard } from "./EntryCard";

export const EntryList : React.FC<IEntryListProps> = ({entries, setActive, setSearchTerm, loadMoreEntries} : IEntryListProps) : JSX.Element => {
    function handleSearchChange(event: ChangeEvent<HTMLInputElement>): void {
        setSearchTerm(event.target.value);
    }

    return (
        <div className="entry-list">
            <form>
                    <label>Archive</label>
                    <br/>
                    <input className="stretch" onChange={handleSearchChange} name="title" type="input"></input>
            </form>
            {entries.map(entry => 
                <EntryCard key={entry.id} setActive={setActive} entry={entry} />
            )}
            <br/>
            <div onClick={loadMoreEntries}>Load more</div>
        </div>
    )
}
