import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import User from './User'

const initialState: User = new User(false, undefined);

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<User>) => {
			//state = new User(action.payload.isAuthorized, action.payload.username, action.payload.name);
			state = action.payload;
			return state;
		}
	}
})


export const { setUser } = userSlice.actions;
export default userSlice.reducer;