export default class QueryParams {
    searchTerm?: string | undefined;
    skip: number | undefined;
    take: number | undefined;

    constructor(searchTerm? : string, skip?: number, take?: number) {
		this.searchTerm = searchTerm;
		this.skip = skip;
		this.take = take;
    }   
}