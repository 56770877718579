import {login} from '../services/Api';
import {Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {setUser} from '../models/UserSlice'

export const Login : React.FC = () => {
    const dispatch = useDispatch();

    return (
        <div className='page page-container'>
            <Form onSubmit={handleSubmit}>
                <Form.Control name="username" placeholder="Username"/>
                <Form.Control name="password" className='mt-3' type="password" placeholder="Password"/>
                <div className="justify-content-center d-flex">
                    <Button className="mt-2" variant="dark" type="submit">Login</Button>
                </div>
            </Form>
        </div>
        
    )

    async function handleSubmit(e : React.ChangeEvent<HTMLFormElement>){
        e.preventDefault();
        const user = await login(e.target.username.value, e.target.password.value);

        if(user !== undefined)
            dispatch(setUser(user))
    }
}