export default class JournalEntry {
    id: number | null;
    title: string;
    content: string;
    createdDate: Date;
    modifiedDate: Date;
    mood: number;

    constructor(id: number | null, title: string = "", content: string = "", mood: number = 0, createdDate: Date = new Date(), modifiedDate: Date = new Date()) {
        this.id = id;
        this.title = title;
        this.content = content;
        this.mood = mood;
        this.createdDate = createdDate;
        this.modifiedDate = modifiedDate;
    }
}