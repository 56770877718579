import IEntryCardProps from "./interfaces/IEntryCardProps"

export const EntryCard : React.FC<IEntryCardProps> = ({entry, setActive}) : JSX.Element => {
    return (
        <div className="mt-3 entry-card" key={entry.id} onClick={() => setActive(entry)}>
            <div className="flex entry-card-header">
                <div>
                    {entry.createdDate.toLocaleDateString()}
                </div>
                <div className={`mood-icon mood-${entry.mood}`}>
                </div>
            </div>
            <div className="entry-card-content">
                {entry.title}
            </div>
        </div>
    )
}
