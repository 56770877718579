export default class User {
    username?: string;
    name?: string;
    isAuthorized: boolean;

    constructor(isAuthorized: boolean, username?: string, name?: string) {
        if(username === undefined || isAuthorized === false){
            this.username = "undefined";
            this.name = "Visitor";
            this.isAuthorized = isAuthorized;
        }
        else {
            this.username = username;
            this.name = name;
            this.isAuthorized = isAuthorized;
        }
    }   
}